$(function () {

    onInit();

    function onInit(){
        var $filterable = $('.state-comparison-table').find('.mobile-filter');
        $filterable.each(function (e) {
            var $self = $(this);
            $self.find('.decor-item').first().addClass('active');
        });

        $('.state-comparison-table .mobile-tabs .decor-item').first().addClass('active');
    }

    // accordion animation
    var isAnimating = false;

    // open first accordion items on load. can't hard code this since its in a cms, der.
    $('.single-accord:first-of-type .b').addClass('current');
    $('.single-accord:first-of-type .h').addClass('active');

    $('.single-accord .h').on('click', function (e) {
        // limiting scope of interaction to just one carousel when more than one carousels are on the page.
        var $curAccord = $(this).parent().parent();
        if (!isAnimating) {
            isAnimating = true;
            if ($(this).next().hasClass('current')) {
                $curAccord.find('.single-accord .h').removeClass('active');
                $(this).next().removeClass('current').slideUp(function () {
                    isAnimating = false;
                });
            } else {
                $curAccord.find('.single-accord .b').slideUp().removeClass('current');
                $curAccord.find('.single-accord .h').removeClass('active');
                $(this).next().addClass('current').slideToggle(function () {
                    isAnimating = false;
                });
                $(this).addClass('active');
            }
        }
    });

    $('.state-comparison-table .mobile-tabs .decor-item').on('click', function () {
        if (!($(this).hasClass('active'))) {
            var stateClicked = $(this).attr('data-state');
            $('.state-comparison-table .decor-item').removeClass('active');
            $('.state-comparison-table .mobile-filter').find('.'+stateClicked+'').addClass('active');
            $('.state-comparison-table .mobile-tabs .decor-item[data-state='+stateClicked+']').addClass('active');
            console.log(stateClicked);
        }
    });

    $('.price-comparison-table .column-toggle').on('click', function () {
        if (!($(this).hasClass('active'))) {
            var clicked = $(this).attr('data-toggle');
            $('.price-comparison-table .column-toggle').removeClass('active');
            $(this).addClass('active');
            $('.price-comparison-table .price').removeClass('active');
            $('.price-comparison-table .price.'+clicked).addClass('active');

        }
    });

    $('.mobile-nav-toggle').on('click', function () {
        $(this).toggleClass('active');
        $('.nav').toggleClass('mobile-active');
        $('.top-bar').toggleClass('mobile-active');
        $('body').toggleClass('scroll-lock');
    });

    $(window).on('resize', function () {
        $('.mobile-nav-toggle').removeClass('active');
        $('.nav').removeClass('mobile-active');
        $('.top-bar').removeClass('mobile-active');
        $('body').removeClass('scroll-lock');
    });

     var $activeSubMenu;

    $('.menu-item-has-children')
    .mouseover(function () {
        $activeSubMenu = $(this).find('.sub-menu');
        $activeSubMenu.addClass('show');
    })
    .mouseout(function () {
        $activeSubMenu.removeClass('show');
    });

    // create a scroll effect for the home page button only, when learn more is clicked from the hero section
    $('.home-page .header--home .btn:first-child').on('click', function (e) {
        e.preventDefault();
        
        var toScroll = $('.decor-textbox--half-width').offset().top;
        $('html, body').animate({
            scrollTop: toScroll
        }, 1000);
    });


    // block additions - TABLES BLOCK SCRIPTS
    // wraps scrollable tables on medium and smaller viewports
    function scrollWrap() {
        $('table').wrap('<div class="scroll-wrapper"></div>');
        $('.scroll-wrapper').before('<div class="scroll-header">Swipe to view more content</div>');
    }
    scrollWrap();
    // show or hide table scroll header based on if overflowing its parent
    function handleScrollResize() {
        $('table').each(function (index) {
            var table = $(this);
            var tableWidth = table.outerWidth();
            var parentWidth = table.parent().outerWidth();
            var scrollHeader = table.parent().prevAll('.scroll-header:first');

            if (tableWidth > parentWidth) {
                // element has overflow
                scrollHeader.show();
            } else {
                // element doesn't overflow
                scrollHeader.hide();
            }
        })
    }
    $(window).on("resize", function () {
        handleScrollResize();
    });
    $(document).ready(function () {
        handleScrollResize();
    });
});